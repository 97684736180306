import React,{memo, useEffect, useRef} from 'react';
import './css/OurVision.css';

const OurVision = memo(props => {
    const divRef = useRef(null);

    function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
     return doc.documentElement.textContent;
    }

    useEffect(()=>{
        divRef.current.innerHTML=htmlDecode(props.visionData && props.visionData.content);
    })
    return (
        <div className='our-vision'>
            <p className='our-vision-header'>{props.visionData && props.visionData.title}</p>
            <div className="row">
                <div className="col-12">
                    <div className='vision-card'>
                    <div ref={divRef} ></div>
            </div>
                </div>
            </div>

        </div>
    )
});

export default OurVision
