import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import './css/Warranty.css';

const Warranty = (props) => {
    const [formData , setFormData]=useState({
         serialNumber:'', issue:'', yourName : '', email:'', description:'',
    });
    const[selectModel, setSelectModel]=useState();
    const [model , setModel] = useState('SELECT MODEL');
    const [activeBtn, setActiveBtn]=useState();
    const [fileState, setFileState]=useState('');
    const [ loading,setLoading]=useState(0);

    
    const apiUrl = 'https://kofs.macart.com.tr';
    const token='6LcsXwEVAAAAAPHtdc-BYVG-Y64KMdnfoIpK33d5';

    useEffect(()=>{
        const fetchData = async () => {
            await axios.get(`${apiUrl}/products`).then((resp)=>{
                const modelData= resp.data.products;
                setSelectModel(modelData);
            })
          };
          fetchData();
      },[])

      const changeHandle = e => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        })
      }
      const submitHandle =  async (e) => {
            e.preventDefault();
             await axios.post(`${apiUrl}/warranty?token=${token}&msg=${formData.description}&real_name=${formData.yourName}&email=${formData.email}&issue=${formData.issue}&model=${model}&serial_number=${formData.serialNumber}&file=${fileState}`)
                .then((response) => {
                if(response.data.class === 'alert-success'){
                    swal({
                        title: "Success!",
                        text: `${response.data.msg}`,
                        icon: "success",
                     });
                }else if(response.data.class === 'alert-danger'){
                    swal({
                        title: "Something is wrong",
                        text: `${response.data.msg}`,
                        icon: "error",
                     });
                }
                }, (error) => {
                console.log(error);
                });
                
      }
      const selectCategory = (index,title) => {
        setModel(title);
        setActiveBtn(index);
      }

      const uploadImage = async (e)=>{
        const fileData=e.target.files[0];
            const data = new FormData();
            data.append('warranty_img', fileData);
            
            await axios.post(`${apiUrl}/upload?token=${token}`, data, {
                onUploadProgress: progressEvent => {
      
                    setLoading(Math.round(progressEvent.loaded / progressEvent.total*100));
                }
            },{
                headers: {
                    'content-type': 'multipart/form-data'
                },
            },
            ).then((resp)=> {
                setFileState(resp.data.url);
            })
      }

    return (
        <div className='warranty-container'>
            <div className='warranty'>
                <p className='warranty-header'>{props.translation&& props.translation.warranty_form}</p>
                <div className='warranty-card'>
                        <form onSubmit={submitHandle} encType="multipart/form-data">
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputState">{props.translation&& props.translation.select_model}</label>

                                <div className='warranty-dropdown'>
                                    <div className="dropdown">
                                        <button className="dropdown-toggle" type="button" 
                                        id="warrantyDropdownButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {model}
                                        </button>

                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {selectModel&&selectModel.map((item,index)=>
                                            <a key={index} onClick={()=>selectCategory(index, item.title)} className={activeBtn===index ? 'dropdown-item active' : 'dropdown-item'}>{item.title}</a>
                                        )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group col-md-12">
                                <label htmlFor="inputAddress">{props.translation&& props.translation.serial_number}</label>
                                <input name='serialNumber'  value={formData.serialNumber} onChange={changeHandle} 
                                type='text' className="form-control" id="inputAddress" placeholder={props.translation&& props.translation.serial_number_ph}/>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAddress">{props.translation&& props.translation.select_issue_ph}</label>
                                <input name='issue'  value={formData.issue} onChange={changeHandle}
                                 type="text" className="form-control" id="inputAddress" placeholder={props.translation&& props.translation.select_issue}/>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAddress">{props.translation&& props.translation.your_name}</label>
                                <input name='yourName'  value={formData.yourName} onChange={changeHandle}
                                 type="text" className="form-control" id="inputAddress" placeholder={props.translation&& props.translation.your_name_ph}/>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAddress">{props.translation&& props.translation.your_email}</label>
                                <input name='email'  value={formData.email} onChange={changeHandle}
                                 type="email" className="form-control" id="inputAddress" placeholder={props.translation&& props.translation.your_email_ph}/>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="exampleFormControlTextarea1">{props.translation&& props.translation.your_desc}</label>
                                <textarea name='description'  value={formData.description} onChange={changeHandle}
                                 placeholder={props.translation&& props.translation.your_desc_ph} className="form-control" id="exampleFormControlTextarea1" rows='4'></textarea>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="exampleFormControlTextarea1">{props.translation&& props.translation.upload_imgs}</label>
                                <div className="custom-file col-md-12">
                            <label className="custom-file-label" htmlFor="validatedCustomFile">{loading>=1 && loading <100 ? `Uploading Progress ${loading}%` : loading === 100 ? 'Uploaded Successfully!' : props.translation&& props.translation.upload_imgs_ph}</label>
                                
                                <input name='warranty_img' onChange={(e)=>uploadImage(e)}
                                 type="file" className="custom-file-input" id="validatedCustomImage" required />
                            </div>
                            </div>
                            
                            <button className={loading===100 ? 'btn-black col-md-12': "btn col-md-12"} type="submit">{props.translation&& props.translation.btn_send_warranty}</button>
                        </div>
                        </form>
                </div>
            </div>
        </div>
    
    )
}

export default Warranty;
