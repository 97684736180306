import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NavSideBar from './NavSideBar';
import './css/Navbar.css';
import {Link, Redirect} from 'react-router-dom';
import kofs from '../images/kofs-logo-high-quality.svg';
import {BsFilterRight,BsX} from 'react-icons/bs';

const Navbar = () => {
    const [sideMenu, setSideMenu]=useState(false);
    const [navbar, setNavbar] = useState([]);
    const [logo, setLogo]=useState('');
    

    const sideMenuOpen=()=>{
        setSideMenu(!sideMenu);
    }
    const apiUrl = 'https://kofs.macart.com.tr';

    useEffect(()=>{
        axios.get(`${apiUrl}/common/menus`).then((resp)=>{
            const menus= resp.data;
            setNavbar(menus);
        })

        axios.get(`${apiUrl}/common/config`)
        .then(resp =>{
        setLogo(resp.data.site.logo)  
      });
    },[])
    
    
    return (
            <nav>
                <div className='logo'>
                    <Link to='/'>
                        <img className='kofs-logo' src={logo&&logo}/>
                    </Link>
                </div>
                
                {sideMenu ? <NavSideBar setSideMenu={setSideMenu} /> :                 
                <ul className='nav-links'>
                   <li><Link to='/'>HOME</Link> </li> 
                    {navbar&&navbar.map((menu,i) => <li key={i}>
                        {menu&&menu.new_segment==='on' ? 
                        <Link target='_blank' to={{pathname: `${menu.link}`}}>{menu.title}</Link> 
                        :
                        <Link to={`/${menu.link}`}>{menu.title}</Link> 
                        }
                        </li>) }

                </ul>}

                <div onClick={sideMenuOpen} className='burger'>
                    {sideMenu ? <BsX/> : <BsFilterRight />}
                </div>
            </nav>
        
    )
}

export default Navbar;
