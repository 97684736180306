import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Loading from '../components/Loading';
import cloud from '../images/Artboard 1.png';
import shootingRange from '../images/shootingRange.png';
import hero2background from '../images/hero-hunting-2.jpg'
import homegun2 from '../images/hero-3-gun.png';
import arrowRight from '../images/arrow-right-models.png';
import tableAccsryImg from '../images/accessory+table.png';
import kofsFooter from '../images/9-layers.svg';
import './HomePage.css';
import arrowDown from '../images/arrow-down.svg';

const HomePage = () => {
    const [translation, setTranslation] = useState([]);
    const [config, setConfig] = useState([]);
    const [slider, setSlider] = useState();
    const[popular, setPopular]=useState([]);

    const apiUrl = 'https://kofs.macart.com.tr';

    useEffect(()=>{
        const fetchData = async () => {
            await axios.get(`${apiUrl}/common/translation`).then((resp)=>{
                const translation= resp.data;
                setTranslation(translation);
            })
            await axios.get(`${apiUrl}/sliders`).then((resp)=>{
              const slidersData= resp.data;
              setSlider(slidersData);
              })
            await axios.get(`${apiUrl}/common/config`).then((resp)=>{
              const configData= resp.data;
              setConfig(configData);
              })
            await axios.get(`${apiUrl}/popular`).then((resp)=>{
              const popularData= resp.data;
              setPopular(popularData&&popularData);
              })
        }
        fetchData();

      },[])

    if(!slider || !translation || !config){
        return <Loading/>;
    }else{
    return (
        <div className='row'>
            <div className='homepage-row'>
                <div className='hero-1 col-12'>
                    <div id="carouselExampleInterval" className="carousel slide carousel-fade" data-ride="carousel">
                        <div className="carousel-inner">
                        {slider&&slider.map((item,i)=>
                            <div key={i} className={`${i===0 ? 'carousel-item active' : 'carousel-item'}`} data-interval="5000">
                                <img src={item.img} className="d-block w-100 hero-1-background" alt="..." />
                            </div>
                        )}
                        </div>
                    </div>
                    <img className='cloud-img' src={cloud} alt="cloud"/>
                    <img className='arrow-down' src={arrowDown} alt='arrow-down'/>
                </div>
                <div className='hero-2 col-12'>
                        <img className='hero-2-background' src={hero2background} alt="hero2"/>
                </div>
                <div className='hero-3 col-12'>
                    <img className='home-gun-2' src={homegun2} alt='home-gun-2' />
                </div>
                <div className='hero-4 col-12'>
                        <div className="models-accessories-row">
                                <div className="guns-row">
                                    <div className="gunswrapper">
                                        <div className="gunswrapper-container">
                                            <div className="guns-textBox">
                                                <img className='shooting-range' src={shootingRange} alt='shootingRange'/>
                                                <h5>{translation && translation.models}</h5>
                                                <p>{translation && translation.models_i}</p>
                                                <Link to='/products'>
                                                    <button className='get-more-button-cavalry'>{translation&&translation.get_more}</button>
                                                </Link> 
                                            </div>
                                            <div className="guns-card-wrapper">
                                            <Link to={`/products/show/${popular[0]&&popular[0].slug}`}>
                                                <div className='guns-card'>
                                                    <img className='gun-models' src={popular[0]&&popular[0].sso_img} alt='' />
                                                    <img className='guns-card-background' src={popular[0]&&popular[0].back_img} alt=""/>
                                                    <h5 className='popular-1-h5'>{popular[0]&&popular[0].title}</h5>
                                                    <p>20 gauge</p>
                                                </div> 
                                            </Link>
                                            <Link to={`/products/show/${popular[1]&&popular[1].slug}`}>
                                                <div id='second-guns-card' className='guns-card'>
                                                <img className='guns-card-background' src={popular[1]&&popular[1].back_img} alt=""/>
                                                <img className='gun-models-2' src={popular[1]&&popular[1].sso_img} alt='' />
                                                    <h5 className='popular-2-h5'>{popular[1]&&popular[1].title}</h5>
                                                    <p>20 gauge</p>
                                                </div> 
                                            </Link>
                                            </div>

                                                <img className='arrow-right-see-modals' src={arrowRight} alt="arrowRight"/>
                                            <Link to='/products' className='see-all-models-link'>
                                                <span className='see-all-models-text'>{translation&&translation.all_models}</span>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            <div className="accessories-section">
                                <div className='table-textBox'>
                                    <h5>{translation&&translation.accessory}</h5>
                                    <p>{translation&&translation.accessory_i}</p>
                                <Link to='/products'>
                                    <button className='get-more-button-accessory'>{translation&&translation.get_more}</button>
                                </Link>
                                </div>

                                <div className='table-imageBox'>
                                <img src={tableAccsryImg} alt="accesories"/>
                                </div>
                            </div>
                        </div>
                
                
                
                </div>
                {/* HERO-6 PASIFLESTIRILDI */}
                {/* <div className='hero-6 col-12'>
                    <img className='waves' src={smokeCloud} alt="smoke-cloud"/>
                    <img className="feel-the-hunting" src={feelHunting} alt="feel-the-hunting"/>
                </div> */}
                <div className="hero-7 col-12">  
                    <div className="row">
                        <div className="footer-header col-12">
                        <h5>WHERE IS KOFS?</h5>
                            <br/>
                            <p>{translation&&translation.header_i}</p>
                        </div>
                        <div className="col-12">
                            <img className='world-map' src={config.site&&config.site.footer_img} alt="around-the-world" />
                        </div>

                        <div className="social col-12">

                                <img className='kofs-footer' src={kofsFooter} alt="kofs"/>
                                
                                <div className='copyright'>
                                    <p>{translation&&translation.copyright}</p>
                                    <p className='design-by'>DESIGN BY MAC ART</p>
                                </div>

                                <div className='footer-cloud'>
                                    <img  src={cloud} alt='cloud' />
                                </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
}

export default HomePage;