import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Loading from '../components/Loading';
import kofsFooter from '../images/9-layers.svg';
import dot from '../images/Ellipse_4.png';
import cloud from '../images/Artboard 1.png';
import {CgArrowLongRight} from 'react-icons/cg';
import SwiperCore,{Navigation,Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './Product.css';


SwiperCore.use([Navigation,Pagination])

const Product = () => {
    const [translation, setTranslation] = useState([]);
    const [categories, setCategories] = useState();
    const [selectedCategory, setSelectedCategory]=useState();
    const [products, setProducts] = useState();
    const [allProducts , setAllProducts] = useState(true);
    const [accessories, setAccessories] = useState();
    const [config, setConfig] = useState([]);
    const [activeBtn, setActiveBtn]=useState();
    const [dropdownText , setDropdownText ] = useState('ALL MODELS');


    const apiUrl = 'https://kofs.macart.com.tr';

    useEffect(()=>{
        axios.get(`${apiUrl}/products`).then((resp)=>{
          const productsData= resp.data;
          setProducts(productsData.products);
          setAccessories(productsData.accessories);
      })
      axios.get(`${apiUrl}/common/config`).then((resp)=>{
        const configData= resp.data;
        setConfig(configData.contact.socials);
    })
     axios.get(`${apiUrl}/common/translation`).then((resp)=>{
        const translation= resp.data;
        setTranslation(translation);
        document.title=`${translation.permalink_2}`;
    })

    axios.get(`${apiUrl}/categories`).then((resp)=>{
        const myCategories= resp.data;
        setCategories(myCategories);
    })

      },[])

    const openCategory = async(index, slug) => {
        await axios.get(`${apiUrl}/categories/show/${slug}`).then((resp)=>{
            const mySelectedCategory= resp.data;
            setSelectedCategory(mySelectedCategory);
            setActiveBtn(index);
            setAllProducts(false);
            setDropdownText(slug);
        })
    }
    const seeAllProducts =()=>{
        setAllProducts(true);
        setActiveBtn(null);
        setDropdownText('ALL MODELS');
    }

    if(!products || !accessories){
        return <Loading />;
    }else{
        return (
            <>
              <div className="row shutguns-row">
                    <div className="shutguns-header col-12">
                        <h5>{translation&&translation.our_shotguns}</h5>
                    </div>
                    <span className='filter-by'>FILTER BY :</span>
                    <div className="shutguns-filter-buttons">
                        <button onClick={()=>seeAllProducts()} className={allProducts ?'active-btn' :'inactive-btn'}>ALL MODELS</button>
                        {categories&&categories.map((item,index)=>
                        <button onClick={()=>openCategory(index, item.slug)} className={activeBtn===index ? 'active-btn' :'inactive-btn'} key={index}>{item.title}</button>
                        )}
                        <div className='category-buttons-dropdown'>
                            <div className="dropdown">
                                <button className="dropdown-toggle" type="button" 
                                id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {dropdownText}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a onClick={()=>seeAllProducts()} className={allProducts ? 'dropdown-item active' : 'dropdown-item'}>ALL MODELS</a>
                                {categories&&categories.map((item,index)=>
                                    <a key={index} onClick={()=>openCategory(index, item.slug)} className={activeBtn===index ? 'dropdown-item active' : 'dropdown-item'}>{item.title}</a>
                                )                        
                                }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container guncards-container col-12">     
                        <div className="row guncards-row">
                            {allProducts ? 
                                products&&products.map((item,i)=> 
                                <div key={i} className="col-12 col-md-6">
                                        <div className="gun-card">
                                            <Link to={`/products/show/${item.slug}`}>
                                                <div className='gun-card-div'>
                                                    <p className='gun-card-arrow-right'><CgArrowLongRight/></p>
                                                    <span className='shut-gun-text'>{item.title}</span>
                                                    <img className='shut-gun-img' src={item.sso_img} alt='shutgun' />
                                                    <div className='dots'>
                                                        <img className='dot1' src={dot} alt='dot' />
                                                        <img className='dot2' src={dot} alt='dot' />
                                                        <img className='dot3' src={dot} alt='dot' />
                                                        <img className='dot4' src={dot} alt='dot' />
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                </div>
                            ) : 
                                selectedCategory&&selectedCategory.products.length <= 0 ?
                                    <h1 className="coming-soon-text">COMING SOON...</h1>
                                :
                                selectedCategory&&selectedCategory.products.map((item,i) =>
                                <div key={i} className="col-12 col-md-6">
                                        <div className="gun-card">
                                            <Link to={`/products/show/${item.slug}`}>
                                                <div className='gun-card-div'>
                                                    <p className='gun-card-arrow-right'><CgArrowLongRight/></p>
                                                    <span className='shut-gun-text'>{item.title}</span>
                                                    <img className='shut-gun-img' src={item.sso_img} alt='shutgun' />
                                                    <div className='dots'>
                                                        <img className='dot1' src={dot} alt='dot' />
                                                        <img className='dot2' src={dot} alt='dot' />
                                                        <img className='dot3' src={dot} alt='dot' />
                                                        <img className='dot4' src={dot} alt='dot' />
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                </div>
                            )
                            }
                            
                            
                        </div>
    
                    </div>
                    <div className='row accessories-row col-12'>
                            <div className="accessories-header col-12">
                                <h5>OUR ACCESSORIES</h5>
                            </div>
                            
                            <Swiper spaceBetween={50}
                                    slidesPerView={window.screen.width<768 ? 1 : 4}
                                    navigation
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}>
                                  {accessories.map((item,i)=> 
                                    <SwiperSlide key={i} style={{width:'auto' , marginTop: 30 , marginBottom:30 , marginLeft:25 , marginRight:25 }}>
                                        <div  className='accessories-card-choke'>
                                            <div className='accessories-card-div'>
                                            <p className='black-header'>{item.title}</p>
                                            <p className='grey-text'>({item.gray_text})</p>
                                            <img className='accessories-img' src={item.sso_img} alt='accessories' />
                                            <p className='radius'>{item.mm_text}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                  )}  
                                
                            </Swiper>
                            
                    </div>
    
                    <div className='section-6 col-12'>
                        <img className='kofs-footer' src={kofsFooter} alt="kofs"/>
                        <div className='copyright'>
                                <p>{translation&&translation.copyright}</p>
                            <p className='design-by'>DESIGN BY MAC ART</p>
                        </div>                   
                                            
                        <div className='footer-cloud'>
                            <img  src={cloud} alt='cloud' />
                        </div>
    
                </div>
              </div>
    
    
            </>
        )
    }

}

export default Product;
