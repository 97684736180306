import React, { useState,useRef,useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import './Cavalry.css';
import ReceiverImages from '../components/ReceiverImages';
import bottom1 from '../images/Path_26.png';
import arrowLeft from '../images/arrow-left.png';
import dot from '../images/Ellipse_4.png';
import {CgArrowLongRight} from 'react-icons/cg';
import cloud from '../images/Artboard 1.png';
import kofsFooterLogo from '../images/9-layers.svg';
import loadingImage from '../images/loading.gif';

const Cavalry = (props) => {
    const [ loading, setLoading]=useState(true);
    const [productDetails, setProductDetails]= useState([]);
    const [selectedCalibre, setSelectedCalibre]= useState(productDetails.item&&productDetails.item.technical_detail[0].models[0]);
    const [activeCalibre, setActiveCalibre]=useState(0);
    const [versionImage , setVersionImage ] = useState(productDetails.item&&productDetails.item.technical_detail[0].models[0].items.sso_img);
    const [receiverImage, setReceiverImage] = useState(false);
    const [receiverTitle,setReceiverTitle] = useState('');
    const [techDetails, setTechDetails] = useState({});
    const [activeBtn, setActiveBtn]=useState(0);
    const [activeBtnTech, setActiveBtnTech]=useState(0);
    const[bigReceiverImage,setBigReceiverImage]=useState();
    const[bigReceiverImageIndex,setBigReceiverImageIndex]=useState();
    const [dropdownText , setDropdownText ] = useState('SX');

    const productId = props.match.params.pid;
    const apiUrl = 'https://kofs.macart.com.tr';
    const productUrl = `${apiUrl}/products/show/${productId}`;

    useEffect(() => {
        const fetchData = async () => {
            await axios.get(productUrl).then((resp)=>{
                const productsDetailsData= resp.data;
                setProductDetails(productsDetailsData);

                const firstVersionImg = productsDetailsData.item&&productsDetailsData.item.technical_detail[0].models[0].items.sso_img;
                setVersionImage(productsDetailsData&&productsDetailsData.item.technical_detail[0].models[0].items.gauge ? firstVersionImg : productsDetailsData&&productsDetailsData.item.technical_detail[1].items.sso_img);
                
                const firstTechDetails= productsDetailsData&&productsDetailsData.item.technical_detail[0].models[0].items;
                setTechDetails(productsDetailsData&&productsDetailsData.item.technical_detail[0].models[0].items.gauge ? firstTechDetails : productsDetailsData&&productsDetailsData.item.technical_detail[1].items);
                document.title=`${productsDetailsData.item.seo_title}`;
            })
          };
          fetchData();

      },[])

    const technical = useRef(null);
    const receivers = useRef(null);

   const scrollToTechnical = () =>
   technical.current.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
  });
   const scrollToReceivers = () =>
   receivers.current.scrollIntoView({
    behavior: "smooth",
    block: "start", 
  });

    const openReceiverImage = (index, title) => {
        setReceiverTitle(title);
        setReceiverImage(!receiverImage);
        const receivers = productDetails.receivers && productDetails.receivers;
        setBigReceiverImage(receivers);
        setBigReceiverImageIndex(index);
    }

    const changeReceiverImgTitle = (index) => {
        setReceiverTitle(productDetails.receivers[index].title);
    }
    const selectCalibre = (index) =>{
        const calibreSelected = productDetails.item&&productDetails.item.technical_detail[index].models;
        setSelectedCalibre(calibreSelected);
        setActiveCalibre(index);
        setVersionImage(productDetails.item&&productDetails.item.technical_detail[index].models[0].items.sso_img)
        setActiveBtn(0);
        setActiveBtnTech(0);
        setTechDetails(productDetails.item&&productDetails.item.technical_detail[index].models[0].items)
        setDropdownText(productDetails.item&&productDetails.item.technical_detail[index].models[0].title);
    }

    const openModelImage = (modelImg, index, title) =>{
        setTimeout(() => {
            setVersionImage(modelImg);
        }, 600);
        setActiveBtn(index);
        setActiveBtnTech(index);
        setTechDetails(productDetails.item.technical_detail && productDetails.item.technical_detail[activeCalibre].models[index].items)
        setLoading(true);
        setDropdownText(title);
    }

    useEffect(() => {
       if(versionImage){
           setLoading(false);
       }
       
    }, [versionImage]);

    const changeTechnicalDetails = (techs,index,title) => {
        setTechDetails(techs);
        setActiveBtnTech(index);
        setActiveBtn(index);
        setVersionImage(productDetails.item&&productDetails.item.technical_detail[activeCalibre].models[index].items.sso_img);
        setDropdownText(title);
    }

    return (
        <div className='row cavalry-row'>
            {
            receiverImage ? 
            <ReceiverImages 
            receiverTitle={receiverTitle} 
            changeReceiverImgTitle={changeReceiverImgTitle}
            setBigReceiverImageIndex={setBigReceiverImageIndex} 
            bigReceiverImageIndex={bigReceiverImageIndex} 
            bigReceiverImage={bigReceiverImage} 
            setReceiverImage={setReceiverImage}
            /> : null
            }
            <div className="section-1 col-12">
                <div>
                    <Link to='/products'>
                        <img className='back-models-img' src={arrowLeft} alt="back-to-models"/>
                        <button className='back-models-btn'>BACK TO MODELS</button>
                    </Link>
                    <p className='cavalry-yellow-header'>{productDetails.item && productDetails.item.title}</p>
                    <p className='yellow-bottom-text'>{productDetails.item && productDetails.item.desc}</p>
                </div>
                {!loading ?
                <div className="photo">
                 <img className='cavalry-gun'  src={versionImage} alt='cavalry' />
                    <p className='model-background-text'>{productDetails.item && productDetails.item.title}</p>
                </div>
                 : 
                 <>
                 <img className='loading-gif' src={loadingImage} alt='cavalry' />
                    <p className='model-background-text'>{productDetails.item && productDetails.item.title}</p>
                </>
                 
                }

                <div className="calibre-buttons">
                    <span>SELECT GAUGE</span>
                    <div className="calibreBtns">
                        {productDetails.item&&productDetails.item.technical_detail.map((calibre,index)=>
                            <button key={index} className={activeCalibre===index ? 'active-calibreBtn' : 'inactive-calibreBtn'} onClick={()=>selectCalibre(index)}>{calibre.calibre}</button>
                            )}
                    </div>
                    <div className='model-buttons'>
                    {selectedCalibre ? 
                            selectedCalibre&&selectedCalibre.map((btn,index)=>
                                btn.title==='default' && !btn.items.gauge ? null : 
                                <button key={index} className={activeBtn===index ? 'active-btn' : 'inactive-btn'} onClick={()=>openModelImage(btn.items.sso_img, index)}>{btn.title}</button>
                                )
                            :
                            productDetails.item&&productDetails.item.technical_detail[0].models.map((item,index)=>
                            <button key={index} className={activeBtn===index ? 'active-btn' : 'inactive-btn'} onClick={()=>openModelImage(item.items.sso_img, index)}>{item.title}</button>
                            )
                        
                    }
                </div>
                </div>


                <div className='model-buttons-dropdown'>
                    <div className="dropdown">
                        <button className="dropdown-toggle" type="button" 
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           {dropdownText}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                        {selectedCalibre ? 
                        selectedCalibre && selectedCalibre.map((button,index)=>
                            button.title==='default' && !button.items.gauge ? null : 
                            <a key={index} onClick={()=>openModelImage(button.items.sso_img, index, button.title)} className={activeBtn===index ? 'dropdown-item active' : 'dropdown-item'}>{button.title}</a>
                        )                        
                        :
                        productDetails.item&&productDetails.item.technical_detail[0].models.map((item,index)=>
                            <a key={index} href="#" className={activeBtn===index ? 'dropdown-item active' : 'dropdown-item'} onClick={()=>openModelImage(item.items.sso_img, index, item.title)}>{item.title}</a>
                        )
                        }

                        </div>
                    </div>
                </div>
                <div className='bottom-buttons'>
                    {/* <a onClick={()=>scrollToFeatures()}>FEATURES</a> */}
                    <a onClick={()=>scrollToTechnical()}>TECHNICAL</a>
                    <a onClick={()=>scrollToReceivers()}>RECEIVERS</a>
                </div>
                <div className="bottom-cloud">
                    <img className='bottom-background' src={bottom1} alt='bottom' />
                </div>

            </div>
            {/* <div className='section-2 col-12'>
            <div className='header-layer'>
                    <div className="header-layer-2">
                        <p  >FEATURES</p>
                    </div>
            </div>
            <div className="featureSection featureLTR">
                <div className="featureImage">
                    <img src={productDetails.item && productDetails.item.feature_right.img} alt="cavalry" className="productImage"/>
                        <div className="podiumVector">
                            <img className='circle-right' src={circleRight} alt="circle"/>
                        </div>
                </div>
                <div className="feature">
                    <h2 className='gauge-shadow-1'>{productDetails.item && productDetails.item.feature_right.title}</h2>
                    <p ref={features}  className='gauge-text-1'>{productDetails.item && productDetails.item.feature_right.text}</p>
                </div>
            </div>

            <div className="featureSection featureRTL">
                <div className="featureImage">
                    <img src={productDetails.item && productDetails.item.feature_left.img} alt="cavalry" className="productImage"/>
                        <div className="podiumVector">
                            <img className='circle-right' src={circleRight} alt="circle"/>
                        </div>
                </div>
                <div className="feature">
                    <h2 className='gauge-shadow-1'>{productDetails.item && productDetails.item.feature_left.title}</h2>
                    <p ref={features}  className='gauge-text-2'>{productDetails.item && productDetails.item.feature_left.text}</p>
                </div>
            </div>

                
            </div> */}
            <div className='section-3 col-12'>
                <div className='header-layer-section-3'>
                        <div className="header-layer-3">
                            <p>TECHNICAL DETAILS</p>
                        </div>
                </div>
                <div className='model-buttons-dropdown-tech'>
                    <div className="dropdown">
                        <button className="dropdown-toggle" type="button" 
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {dropdownText}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {selectedCalibre ? 
                            selectedCalibre&&selectedCalibre.map((btn,index)=>
                                btn.title==='default' && !btn.items.gauge ? null : 
                                <a key={index} className={activeBtnTech===index ? 'dropdown-item active' : 'dropdown-item'} onClick={()=>changeTechnicalDetails(btn.items, index,btn.title)}>{btn.title}</a>
                                )
                            :
                            productDetails.item&&productDetails.item.technical_detail[0].models.map((item,index)=>
                            <a key={index} className={activeBtnTech===index ? 'dropdown-item active' : 'dropdown-item'} onClick={()=>changeTechnicalDetails(item.items, index,item.title)}>{item.title}</a>
                            )
                        
                    }
                        </div>
                    </div>
                </div>

                <div className="calibre-buttons-technical">
                    <span>SELECT GAUGE</span>
                    <div className="calibreBtns-technical">
                        {productDetails.item&&productDetails.item.technical_detail.map((calibre,index)=>
                            <button key={index} className={activeCalibre===index ? 'active-calibreBtn-technical' : 'inactive-calibreBtn-technical'} onClick={()=>selectCalibre(index)}>{calibre.calibre}</button>
                            )}
                    </div>

                <div  className='model-buttons-technical'>
                {selectedCalibre ? 
                            selectedCalibre&&selectedCalibre.map((btn,index)=>
                                btn.title==='default' && !btn.items.gauge ? null : 
                                <button key={index} className={activeBtnTech===index ? 'active-btn' : 'inactive-btn'} onClick={()=>changeTechnicalDetails(btn.items, index)}>{btn.title}</button>
                                )
                            :
                            productDetails.item&&productDetails.item.technical_detail[0].models.map((item,index)=>
                            <button key={index} className={activeBtnTech===index ? 'active-btn' : 'inactive-btn'} onClick={()=>changeTechnicalDetails(item.items, index)}>{item.title}</button>
                            )
                        
                    }
                </div>

                </div>      

                <div  className="container detail-container">           
                    <div className="row detail-row"> 
                        <div  className='detail-div col-6'>
                            <p className='detail-header'>GAUGE</p>
                            <p className='detail-value'>{techDetails.gauge}</p>
                            <hr className='solid'/>
                        </div>
                        <div className='detail-div col-6'>
                            <p className='detail-header'>BARREL</p>
                            <p className='detail-value'>{techDetails.barrel}</p>        
                            <hr className='solid'/>
                        </div>
                    </div>
                    <div ref={technical} className=" row detail-row"> 
                        <div className='detail-div col-6'>
                            <p className='detail-header'>BARREL LENGTH</p>
                            <p className='detail-value'>
                            <div dangerouslySetInnerHTML={{__html: techDetails.barrel_length}} /> </p>
                            <hr className='solid'/>
                        </div>
                        <div className='detail-div col-6'>
                            <p className='detail-header'>CHOKES</p>
                            <p className='detail-value'>
                            <div dangerouslySetInnerHTML={{__html: techDetails.chokes}} /> </p>      
                            <hr className='solid'/>
                        </div>
                    </div>
                    <div className="row detail-row"> 
                        <div className='detail-div col-6'>
                            <p className='detail-header'>AVARAGE WEIGHT*</p>
                            <p className='detail-value'>
                            <div dangerouslySetInnerHTML={{__html: techDetails.avarage_weight}} /> </p>
                            <hr className='solid'/>
                        </div>
                        <div className='detail-div col-6'>
                            <p className='detail-header'>SIGHT(S)</p>
                            <p className='detail-value'>
                            <div dangerouslySetInnerHTML={{__html: techDetails.sight}} /> </p>     
                            <hr className='solid'/>
                        </div>
                    </div>
                    <div className="row detail-row"> 
                        <div className='detail-div col-6'>
                            <p className='detail-header'>RECEIVER</p>
                            <p className='detail-value'>
                            <div dangerouslySetInnerHTML={{__html: techDetails.receiver}} /> </p>
                            <hr className='solid'/>
                        </div>
                        <div className='detail-div col-6'>
                            <p className='detail-header'>FORE-END</p>
                            <p className='detail-value'>
                            <div dangerouslySetInnerHTML={{__html: techDetails.fore_end}} /> </p>        
                            <hr className='solid'/>
                        </div>
                    </div>
                    <div className="row detail-row"> 
                        <div className='detail-div col-6'>
                            <p className='detail-header'>STOCK</p>
                            <p className='detail-value'>
                            <div dangerouslySetInnerHTML={{__html: techDetails.stock}} /> </p>
                            <hr className='solid'/>
                        </div>
                        <div className='detail-div col-6'>
                            <p className='detail-header'>GUN TRIGGER</p>
                            <p className='detail-value'>
                            <div dangerouslySetInnerHTML={{__html: techDetails.gun_trigger}} /> </p>        
                            <hr className='solid'/>
                        </div>
                    </div>
                    <div className="row detail-row"> 
                        <div  className='detail-div col-6'>
                            <p className='detail-header'>CHAMBER</p>
                            <p className='detail-value'>
                            <div dangerouslySetInnerHTML={{__html: techDetails.chamber}} /> </p>
                            <hr className='solid'/>
                        </div>
                        <div className='detail-div col-6'>
                            <p className='detail-header'>UNLOADING</p>
                            <p className='detail-value'>
                            <div dangerouslySetInnerHTML={{__html: techDetails.unloading}} /> </p>       
                            <hr className='solid'/>
                        </div>
                    </div>
                        <p ref={receivers} className='grey-text-bottom'>Specifications subject to change without notice. (*) Weight approximate. Varies due to stock density.</p>
                </div>
            </div>
            <div  className='section-4 col-12'>
                <div  className='header-layer-section-4'>
                    <div className="header-layer-4">
                        <p>RECEIVERS</p>
                    </div>
                </div> 
                <div className="container receivers-container">
                    <div   className="row receivers-row justify-content-start">
                        {productDetails.receivers && productDetails.receivers.map((receive,index)=>
                        !receive ? null :
                            <div key={index}  className="receivers-div col-6 col-md-3">
                            <img onClick={() => openReceiverImage(index, receive.title)} className='img-fluid' src={receive.thumb_img} alt='receivers' />
                            <p className='rec-number'>{receive.title}</p>
                        </div>
                        )}
                    </div>
                    
                </div>
            </div>
            <div  className='section-5 col-12'>
                <div className='header-layer-section-5'>
                    <div className="header-layer-5">
                        <p>OTHER MODELS</p>
                    </div>
                </div> 
                <div className="slider">
                    <div id="to-indicator" className="carousel slide" data-ride="carousel" >
                        <ol className="carousel-indicators">
                        {productDetails.another_models && productDetails.another_models.map((indicator,index)=>
                            <li key={index} data-target="#to-indicator" data-slide-to={index} className={index===0 ? "active" : null}>{index}</li>
                        )}
                        </ol>
                        <div className="carousel-inner" >
                            {productDetails.another_models && productDetails.another_models.map((model,i)=>
                                <div key={i} className={i===0 ? "carousel-item active" : 'carousel-item'}>
                                <div className="slider-card">
                                    <Link  to={`/products/show/${model.slug}`}>
                                            <div  className='slider-card-div'>
                                                <p className='slider-card-text'>{model.title}</p>
                                                <p className='slider-arrow'><CgArrowLongRight/></p>
                                                <img className='slider-card-img' src={model.sso_img} alt='shutgun' />
                                                <div className='slider-dots'>
                                                    <img className='slider-dot1' src={dot} alt='dot' />
                                                    <img className='slider-dot2' src={dot} alt='dot' />
                                                    <img className='slider-dot3' src={dot} alt='dot' />
                                                    <img className='slider-dot4' src={dot} alt='dot' />
                                                </div>
                                            </div>
                                    </Link>
                                </div>
                            </div>
                            )}
                            <a className="carousel-control-prev" href="#to-indicator" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#to-indicator" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                        
                    </div>

                </div>
            </div> 
            <div className='section-6 col-12'>
                    <img className='kofs-footer' src={kofsFooterLogo} alt="kofs"/>
                    <div className='copyright'>
                            <p>COPYRIGHT BY KOFS - 2020</p>
                        <p className='design-by'>DESIGN BY MAC ART</p>
                    </div>                   
                                        
                    <div className='footer-cloud'>
                        <img  src={cloud} alt='cloud' />
                    </div>

            </div>
        </div>
    )
}

export default Cavalry;
