import React,{memo, useEffect, useRef} from 'react';
import './css/Faq.css';

const Faq = memo(props => {
    const divRef = useRef(null);

    function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
     return doc.documentElement.textContent;
    }

    useEffect(()=>{
        divRef.current.innerHTML=htmlDecode(props.faqData && props.faqData.content);
    })

    return (
        <div className='faq'>
            <p className='faq-header'>{props.translation && props.translation.fag_large}</p>
            <div className="row">
                <div className="col-12 col-md-12">
                    <div className='faq-card'>
                        <div className="faq-item col-12">
                        <div ref={divRef} ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Faq;
