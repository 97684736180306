import React, { useState,useEffect } from 'react';
import './css/UserManuals.css';
import axios from 'axios';
import swal from 'sweetalert';
import './css/Warranty.css';


const UserManuals = (props) => {
    const[selectModel, setSelectModel]=useState();
    const [model , setModel] = useState('SELECT MODEL');
    const [activeBtn, setActiveBtn]=useState();
    const [formData , setFormData]=useState({
        phone:'', yourName : '', email:'',
    })

    const apiUrl = 'https://kofs.macart.com.tr';
    const token='6LcsXwEVAAAAAPHtdc-BYVG-Y64KMdnfoIpK33d5';

    useEffect(()=>{
        const fetchData = async () => {
            await axios.get(`${apiUrl}/products`).then((resp)=>{
                const modelData= resp.data.products;
                setSelectModel(modelData);
            })
          };
          fetchData();
      },[]);

      const submitHandle =  async (e) => {
        e.preventDefault();
         await axios.post(`${apiUrl}/supportform?token=${token}&real_name=${formData.yourName}&email=${formData.email}&phone=${formData.phone}&model=${model}`)
            .then((response) => {
            if(response.data.class === 'alert-success'){
                swal({
                    title: "Success!",
                    text: `${response.data.msg}`,
                    icon: "success",
                 });
            }else if(response.data.class === 'alert-danger'){
                swal({
                    title: "Something is wrong",
                    text: `${response.data.msg}`,
                    icon: "error",
                 });
            }
            }, (error) => {
            console.log(error);
            });
            
  }

    const changeHandle = e => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        })
      }
      const selectCategory = (index,title) => {
        setModel(title);
        setActiveBtn(index);
      }
    
    return (
        <div className='warranty-container'>
            <p className='warranty-header'>USER MANUALS</p>
            <div className="row">
                <div className="warranty col-12 col-md-8">
                <div className='warranty-card'>
                        <form onSubmit={submitHandle} encType="multipart/form-data">
                        <div className="form-row">
                        <div className="form-group col-md-12">
                                <label htmlFor="inputState">{props.translation&& props.translation.select_model}</label>
                                <div className='warranty-dropdown'>
                                    <div className="dropdown">
                                        <button className="dropdown-toggle" type="button" 
                                        id="warrantyDropdownButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {model}
                                        </button>

                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {selectModel&&selectModel.map((item,index)=>
                                            <a key={index} onClick={()=>selectCategory(index, item.title)} className={activeBtn===index ? 'dropdown-item active' : 'dropdown-item'}>{item.title}</a>
                                        )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAddress">{props.translation&& props.translation.your_name}</label>
                                <input name='yourName'  value={formData.yourName} onChange={changeHandle}
                                 type="text" className="form-control" id="inputAddress" placeholder={props.translation&& props.translation.your_name_ph}/>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAddress">{props.translation&& props.translation.your_email}</label>
                                <input name='email'  value={formData.email} onChange={changeHandle}
                                 type="email" className="form-control" id="inputAddress" placeholder={props.translation&& props.translation.your_email_ph}/>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAddress">{props.translation&& props.translation.your_phone}</label>
                                <input name='phone'  value={formData.phone} onChange={changeHandle}
                                 type="tel" className="form-control" id="inputAddress" placeholder={props.translation&& props.translation.your_phone_ph}/>
                            </div>

                            
                            <button className='btn-black col-md-12' type="submit">{props.translation&& props.translation.ask_catalog}</button>
                        </div>
                        </form>
                </div>
                </div>  
            </div>
        </div>
    )
}

export default UserManuals
