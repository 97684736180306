import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import dog from '../images/dog.jpg';
import footerKofs from '../images/footer-kofs.svg';
import './Contact.css';

const Contact = () => {
    const [contact , setContact]=useState([]);
    const [translation, setTranslation]= useState();
    const [formData , setFormData]=useState({
        fullName : '', subject:'', email:'', yourMessage:'',
    })

    const apiUrl = 'https://kofs.macart.com.tr';
    // 6LcsXwEVAAAAAPHtdc-BYVG-Y64KMdnfoIpK33d5
    const token='6LcsXwEVAAAAAPHtdc-BYVG-Y64KMdnfoIpK33d5';

    useEffect(()=>{
        const fetchData = async () => {
            await axios.get(`${apiUrl}/common/config`).then((resp)=>{
                const contactData= resp.data;
                setContact(contactData.contact.contact);
            })
            await axios.get(`${apiUrl}/common/translation`).then((resp)=>{
                const translationData= resp.data;
                setTranslation(translationData);
            })

            
          };
          fetchData();
      },[])
      

      const changeHandle = e => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        })
      }
      const submitHandle = async (e) => {
        e.preventDefault();
        await axios.post(`${apiUrl}/contact/?token=${token}&real_name=${formData.fullName}&email=${formData.email}&subject=${formData.subject}&msg=${formData.yourMessage}`)
        .then((response) => {
          if(response.data.class === 'alert-success'){
            swal({
                title: "Success!",
                text: `${response.data.msg}`,
                icon: "success",
             });
        }else if(response.data.class === 'alert-danger'){
            swal({
                title: "Something is wrong!",
                text: `${response.data.msg}`,
                icon: "error",
             });
          }
        }, (error) => {
          console.log(error);
        });
      }


    return (
        <>
        <div className='contact-page-container'>
            <div className="row contact-page-row">
                    <div className="dog-row col-12 col-lg-6">
                        <div className="dog-card">
                            <img src={dog} alt="born-hunter"/>
                            <p className='born-hunter'>BORN HUNTER</p>
                            <hr className='born-hunter-hr'/>
                        </div>
                    </div>
                    <div className="info-contact-row col-12 col-lg-6">
                        <div className="info-card">
                            <p className='info-header'>{translation&&translation.contact_title}</p>
                            <div className="row info-card-row">
                            <div className="col-6 col-lg-4">
                                <div className="gsm">
                                    <p className='head'>GSM</p>
                                    <p className='info'>{contact&&contact.phone}</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-4">
                                <div className="e-mail">
                                    <p className='head'>E-MAIL</p>
                                    <p className='info'>{contact&&contact.email}</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className='address'>
                                <p className='head'>ADDRESS</p>
                                <p className='info'>{contact&&contact.address}</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='contact-form'>
                    <form onSubmit={submitHandle}>
                        <p className='form-head'>{translation&&translation.contact_lead}</p>
                        <p className='form-info'>{translation&&translation.contact_info}</p>
                        <div className="form-row">
                            <div className="form-group col-12">
                                <input name='fullName'  value={formData.fullName} onChange={changeHandle}
                                 type='text' className="form-control" id="inputAddress" placeholder={translation&&translation.full_name}/>
                            </div>
                            <div className="form-group col-12">
                                <input name='subject' value={formData.subject} onChange={changeHandle}
                                 type="text" className="form-control" id="inputAddress" placeholder={translation&&translation.subject}/>
                            </div>
                            <div className="form-group col-12">
                                <input name='email' value={formData.email} onChange={changeHandle}
                                 type="email" className="form-control" id="inputAddress" placeholder={translation&&translation.email}/>
                            </div>

                            <div className="form-group col-12">
                                <textarea name='yourMessage' value={formData.yourMessage} onChange={changeHandle}
                                 placeholder={translation&&translation.msg} className="form-control" id="exampleFormControlTextarea1" rows='2'></textarea>
                            </div>
                            
                            <button className="send-btn col-4" type="submit">{translation&&translation.btn_send_form}</button> 
                        </div>
                </form>
            </div>
                    </div>
            </div>
        </div>
        <footer>
                <div className="footer-kofs">
                    <img src={footerKofs} alt='kofs' />
                </div>
                <div className="copyright">
                    <p className='copyright-by-kofs'>{translation&&translation.copyright}</p>
                    <p className='copyright-2'>DESIGN BY MAC ART</p>
                </div> 
        </footer>
        </>
    )
}

export default Contact;

{/* <div className='row'>
<div className='contact-container'>
    <div className="contact-row">
            <div className="col-md-6">
                <div className="dog-card">
                    <img src={dog} alt="born-hunter"/>
                    <p className='born-hunter'>BORN HUNTER</p>
                    <hr className='born-hunter-hr'/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="info-card">
                    <p className='info-header'>CONTACT INFORMATION</p>
                    <div className="info-card-row">
                        <div className='row'>
                            <div className="col-6 col-md-4">
                                <div className="gsm">
                                    <p className='head'>GSM</p>
                                    <p className='info'>+90(555) 556 1235</p>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className="e-mail">
                                    <p className='head'>E-MAIL</p>
                                    <p className='info'>info@kofs.com.tr</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className='address'>
                                <p className='head'>ADDRESS</p>
                                <p className='info'>Isparta Organize Sanayi 
                                    Bölgesi 109 Cd. No: 5 Isparta / Turkey</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div className='contact-form'>
                <form>
                    <p className='form-head'>YOU CAN TOUCH US</p>
                        <p className='form-info'>She reached her goal, exhausted. Even more chilling to her was that the euphoria that she thought she’d feel upon reaching it wasn’t there. Something wasn’t right. Was this the only feeling she’d have for over five years of hard work?</p>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <input type='text' class="form-control" id="inputAddress" placeholder="ENTER YOUR SERIAL NUMBER"/>
                        </div>
                        <div class="form-group col-12">
                            <input type="text" class="form-control" id="inputAddress" placeholder="SELECT AN ISSUE"/>
                        </div>
                        <div class="form-group col-12">
                            <input type="text" class="form-control" id="inputAddress" placeholder="ENTER YOUR FULL NAME"/>
                        </div>

                        <div class="form-group col-12">
                            <textarea placeholder='ENTER YOUR DESCRIPTION HERE' class="form-control" id="exampleFormControlTextarea1" rows='2'></textarea>
                        </div>
                        
                        <button class="send-btn col-4" type="submit">SEND FORM</button>
                        
                        
                    </div>
                </form>
            </div>
            </div>   
        
    </div>               

</div> 

</div> */}