import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Faq from '../components/Faq';
import Suggestions from '../components/Suggestions';
import Warranty from '../components/Warranty';
import UserManuals from '../components/UserManuals';
import gun from '../images/_CHN6608.png';
import footerKofs from '../images/footer-kofs.svg';
import './Support.css';

const Support = () => {
    const [translation, setTranslation]= useState();
    const[faq, setFaq]=useState(true);
    const[suggestions, setSuggestions]=useState(false);
    const[warranty, setWarranty]=useState(false);
    const[manuals, setManuals]=useState(false);
    const [support , setSupport]=useState([]);

    const apiUrl = 'https://kofs.macart.com.tr';

    useEffect(()=>{
        const fetchData = async () => {
            await axios.get(`${apiUrl}/support`).then((resp)=>{
                const supportData= resp.data;
                setSupport(supportData);
            })
            await axios.get(`${apiUrl}/common/translation`).then((resp)=>{
                const translationData= resp.data;
                setTranslation(translationData);
            })
          };
          fetchData();
      },[])



    const faqOpen = () =>{
        setFaq(true);
        setSuggestions(false);
        setWarranty(false);
        setManuals(false);

    }
    const suggestionsOpen = () =>{
        setFaq(false);
        setSuggestions(true);
        setWarranty(false);
        setManuals(false);
    }
    const warrantyOpen = () =>{
        setFaq(false);
        setSuggestions(false);
        setWarranty(true);
        setManuals(false);
    }
    const userManualsOpen = () =>{
        setFaq(false);
        setSuggestions(false);
        setWarranty(false);
        setManuals(true);
    }


    return (
        <div className='support-card-container'>
        <div className='support-card'>
            <div className='support'>
                    <p className='about-us-support'>{translation&&translation.permalink_3}</p>
                    <p className='get-more-info'>{translation&&translation.support_i}</p>
                    {support.faq&&support.faq.seo_title === "not-visible" ? null : <button onClick={()=>faqOpen()}>FAQ</button>}
                    {support.suggestions&&support.suggestions.seo_title === "not-visible" ? null : <button onClick={()=>suggestionsOpen()} className='vision-mission'>SUGGESTIONS</button>}
                    <button onClick={()=>warrantyOpen()} className='vision-mission'>{translation&&translation.warranty}</button>
                    <button onClick={()=>userManualsOpen()} className='vision-mission-manual'>{translation&&translation.user_manuals}</button>
            </div>
            <div className="support-gun-img">
                <img className='support-gun' src={gun} alt='gun1'/>
            </div>
        </div>
        <div className="row components">
            <div className="col-md-12">
                {faq? <Faq translation={translation} faqData={support&&support.faq} /> : 
                suggestions ? <Suggestions translation={translation} suggestionsData={support&&support.suggestions} /> : 
                warranty ? <Warranty translation={translation} /> : 
                manuals ? <UserManuals translation={translation} manualsData={support&&support.user_manuals} /> : null}
            </div>
        </div>
        <footer>
                <div className="footer-kofs">
                    <img src={footerKofs} alt='kofs' />
                </div>
                <div className="copyright">
                    <p className='copyright-by-kofs'>{translation&&translation.copyright}</p>
                    <p className='copyright-2'>DESIGN BY MAC ART</p>
                </div> 
        </footer>
        
        </div>
    )
}

export default Support;
