import React from 'react';
import './css/ReceiverImages.css';
import {BsX, BsArrowLeft, BsArrowRight} from 'react-icons/bs';

const ReceiverImages = (props) => {
    const closeSlider = () => {
        props.setReceiverImage(false);
    }

    const prevImg = () => {
        if(props.bigReceiverImageIndex <= 0 ){
            props.setBigReceiverImageIndex(props.bigReceiverImage.length-1);
            props.changeReceiverImgTitle(props.bigReceiverImage.length-1);
        }else{
            props.setBigReceiverImageIndex(props.bigReceiverImageIndex-1);
            props.changeReceiverImgTitle(props.bigReceiverImageIndex-1);
        }
    }
    const nextImg = () =>{
        if(props.bigReceiverImageIndex >= props.bigReceiverImage.length-1 ){
            props.setBigReceiverImageIndex(0);
            props.changeReceiverImgTitle(0);
        }else{
            props.setBigReceiverImageIndex(props.bigReceiverImageIndex+1);
            props.changeReceiverImgTitle(props.bigReceiverImageIndex+1);
        }
    }

    return (
        <div className='receiver-slider-container'>
            <i onClick={()=>closeSlider()} className='slider-close-button'><BsX/></i>
            <div id="receiver-target" className="carousel slide carousel-fade receiver-slide" data-ride="carousel"> 
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className='receiver-img-carousel'>
                            <img src={props.bigReceiverImage[props.bigReceiverImageIndex]&&props.bigReceiverImage[props.bigReceiverImageIndex].sso_img} className="receiver-img d-block w-100" alt="..." />
                            <span className='receiver-title'>{props.receiverTitle}</span>

                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" onClick={()=>prevImg()} role="button" data-slide="prev">
                    <BsArrowLeft />
                </a>
                <a className="carousel-control-next" onClick={()=>nextImg()} role="button" data-slide="next">
                    <BsArrowRight />
                </a>
            </div>
        </div>
    )
}

export default ReceiverImages;
