import React,{memo, useEffect, useRef} from 'react';
import './css/KnowUs.css';
 


const KnowUs = memo(props => {
    const divRef = useRef(null);

    function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
     return doc.documentElement.textContent;
    }

    useEffect(()=>{
        divRef.current.innerHTML=htmlDecode(props.knowUsData && props.knowUsData.content);
    })

    
    return (
        <div className='know-us'>
            <p className='know-us-header'>{props.knowUsData && props.knowUsData.title}</p>
            <div className="row">
                <div className='col-12 col-md-12'>
                    <div className="know-us-card">                       
                            <div ref={divRef} ></div>
                    </div>
                </div>
            </div>

        </div>
    )
});

export default KnowUs;