import { useState,useEffect } from 'react';
import './App.css';
import {Switch, Route, withRouter} from 'react-router-dom';
import Navbar from './components/Navbar';
import NavbarBlack from './components/NavbarBlack';
import HomePage from './pages/HomePage';
import About from './pages/About';
import Product from './pages/Product';
import Support from './pages/Support';
import Contact from './pages/Contact';
import Cavalry from './pages/Cavalry';
import axios from 'axios';
import ScrollToTop from './components/ScrollToTop';

function App(props) {
      const [pathName , setPathName]=useState(false);

  
  const changeNavbarStyle = (path) => {
    if(path.includes("/products/show")){
           setPathName(true);
       }else{
        setPathName(false);
      }
  }
  const apiUrl = 'https://kofs.macart.com.tr';

  useEffect(()=>{
    props.history.listen(()=>{
      changeNavbarStyle(window.location.pathname);
    })
    
    axios.get(`${apiUrl}/common/config`)
      .then(resp =>{
        var faviconIco= resp.data.site.favicon;
        var title=resp.data.site.title;
        // var cookieData =resp.data.cookie.text;

        // document.getElementById('cookie-p').append(cookieData && cookieData);

        // const cookieContainer = document.getElementsByClassName('cookie-container');
        // const cookieContainerButton = document.getElementById('cookie-btn');

        // console.log(cookieContainer.classList);
        
        // cookieContainerButton.addEventListener('click', ()=>{
        //   cookieContainer.classList.remove('active');
        //   localStorage.getItem('cookieBannerDisplayed', 'true')
        //   setTimeout(()=>{
        //     if(!localStorage.getItem('cookieBannerDisplayed')){
        //       cookieContainer.classList.add('active')
        //     }
        //   },2000);
        // })
        
        
        // var robots = resp.data.seo.robots;
        // var jscodes = resp.data.seo.confirmation_codes;

        document.title=`${title}`;
        
        document.getElementById('dynamic-favicon').setAttribute('href', `${faviconIco}`);        
        
      });
  });

  return (
    <>
    <ScrollToTop>
    {pathName ? <NavbarBlack/> :  <Navbar />}
    <Switch>
      <Route exact path='/' component={HomePage} />
      <Route exact path='/about' component={About} />
      <Route exact path='/products' component={Product} />
      <Route exact path='/support' component={Support} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/products/show/:pid' component={withRouter(Cavalry)} />
    </Switch>
    </ScrollToTop>
    </>
  );
}

export default withRouter(App);
