import React, { useState, useEffect } from 'react';
import axios from 'axios';
import KnowUs from '../components/KnowUs';
import OurVision from '../components/OurVision';
import OurMission from '../components/OurMission';
import gun from '../images/_CHN2751.png';
import footerKofs from '../images/footer-kofs.svg';
import './About.css';

const About = () => {
    const [translation, setTranslation] = useState([]);
    const[knowUs, setKnowUs]=useState(true);
    const[ourVision, setOurVision]=useState(false);
    const[ourMission, setOurMission]=useState(false);
    const [about , setAbout]=useState([]);

    const apiUrl = 'https://kofs.macart.com.tr';

    useEffect(()=>{
        const fetchData = async () => {
            await axios.get(`${apiUrl}/about`).then((resp)=>{
                const aboutData= resp.data;
                setAbout(aboutData);
            })
            await axios.get(`${apiUrl}/common/translation`).then((resp)=>{
                const translation= resp.data;
                setTranslation(translation);
                document.title=`${translation.permalink_1}`;
            })
          };
          fetchData();
      },[])

    const knowUsOpen = () =>{
        setKnowUs(true);
        setOurVision(false);
        setOurMission(false);
    }
    const ourVisionOpen = () =>{
        setKnowUs(false);
        setOurVision(true);
        setOurMission(false);
    }
    const ourMissionOpen = () =>{
        setKnowUs(false);
        setOurVision(false);
        setOurMission(true);
    }

    
    return (
        <div className='about-us-container'>
        <div className='title-card'>
            <div className='title'>
                    <p className='about-us-title'>{translation&&translation.permalink_1}</p>
                    <p className='get-more-info'>{translation&&translation.about_us_i}</p>
                    <button onClick={()=>knowUsOpen()}>{translation&&translation.know_us}</button>
                    <button onClick={()=>ourVisionOpen()} className='vision-mission'>{translation&&translation.our_vission}</button>
                    <button onClick={()=>ourMissionOpen()} className='vision-mission'>{translation&&translation.our_mission}</button>
            </div>
            <div className="gun-img">
                <img className='gun' src={gun} alt='gun1'/>
            </div>
            
        </div>
        <div className="row components">
            <div className="col-md-12">
                {knowUs? <KnowUs knowUsData={about.know_us} /> : ourVision ? <OurVision visionData={about.our_vission} /> : ourMission ? <OurMission missionData={about.our_mission} /> : null}
            </div>
        </div>
        
        <footer>
                <div className="footer-kofs">
                    <img src={footerKofs} alt='kofs' />
                </div>
                <div className="copyright">
                    <p className='copyright-by-kofs'>{translation&&translation.copyright}</p>
                    <p className='copyright-2'>DESIGN BY MAC ART</p>
                </div> 
        </footer>
        
        </div>
    )
}

export default About
