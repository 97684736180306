import React from 'react';
import kofsWhiteLogo from '../images/kofs-white.svg';

const Loading = () => {
    return (
        <div className='loading-component'>
            <img src={kofsWhiteLogo} alt=""/>
        </div>
    )
}

export default Loading;
