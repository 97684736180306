import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBlackSideBar from './NavBlackSideBar';
import './css/NavbarBlack.css';
import {Link} from 'react-router-dom';
import {BsFilterRight,BsX} from 'react-icons/bs';
// import kofsWhite from '../images/kofs-white.svg';

const NavbarBlack = () => {
    const [blackSideMenu, setBlackSideMenu]=useState(false);
    const [navbar, setNavbar] = useState([]);
    const [logo, setLogo]=useState('');

        const blackSideMenuOpen=()=>{
        setBlackSideMenu(!blackSideMenu);
    }

    const apiUrl = 'https://kofs.macart.com.tr';

    useEffect(()=>{
        axios.get(`${apiUrl}/common/menus`).then((resp)=>{
            const menus= resp.data;
            setNavbar(menus);
        })
        axios.get(`${apiUrl}/common/config`)
        .then(resp =>{
        setLogo(resp.data.site.logo)  
      });
    },[])

    return (
            <nav className='nav-black'>
                <div className='logo-white'>
                    <Link to='/'>
                        <img className='kofs-logo' src={logo&&logo}/>
                    </Link>
                </div>
                
                {blackSideMenu ? <NavBlackSideBar setBlackSideMenu={setBlackSideMenu} /> :                 
                <ul className='nav-links-black'>
                   <li><Link to='/'>HOME</Link> </li> 
                   {navbar&&navbar.map((menu,i) => <li key={i}>
                        {menu&&menu.new_segment==='on' ? 
                        <Link target='_blank' to={{pathname: `${menu.link}`}}>{menu.title}</Link> 
                        :
                        <Link to={`/${menu.link}`}>{menu.title}</Link> 
                        }
                        </li>) }

                </ul>}
                <div onClick={blackSideMenuOpen} className='burger-black'>
                    {blackSideMenu ? <BsX/> : <BsFilterRight />}
                </div>
            </nav>
    )
}

export default NavbarBlack;
