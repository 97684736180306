import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import './css/Navbar.css';

const NavSideBar = (props) => {
    const [navbar, setNavbar] = useState([]);
    
    const apiUrl = 'https://kofs.macart.com.tr';

    useEffect(()=>{
        axios.get(`${apiUrl}/common/menus`).then((resp)=>{
            const menus= resp.data;
            setNavbar(menus);
        })
    },[])

    const closeSideNavbar = () =>{
        props.setSideMenu(false);
    }
    return (
        <div className='nav-side-bar'>
            <ul className='nav-active'>
                <li onClick={closeSideNavbar}>  <Link to='/'>HOME</Link> </li> 
                {navbar&&navbar.map((menu,i) => 
                <li onClick={closeSideNavbar} key={i}>
                        {menu&&menu.new_segment==='on' ? 
                        <Link target='_blank' to={{pathname: `${menu.link}`}}>{menu.title}</Link> 
                        :
                        <Link to={`/${menu.link}`}>{menu.title}</Link> 
                        }
                        </li>) }
            </ul>
        </div>
    )
}

export default NavSideBar;