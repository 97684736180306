import React,{memo, useEffect, useRef} from 'react';
import './css/Suggestions.css';

const Suggestions = memo(props => {
    const divRef = useRef(null);

    function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
     return doc.documentElement.textContent;
    }

    useEffect(()=>{
        divRef.current.innerHTML=htmlDecode(props.suggestionsData && props.suggestionsData.content);
    })
    
    return (
        <div className='suggestions'>
            <p className='suggestions-header'>SUGGESTIONS</p>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className='suggestions-card'>
                                <div className="suggestions-item col s12">
                                <div ref={divRef} ></div>
                                </div>

                        </div>
                    </div>   
                </div>
            </div>
    )
})

export default Suggestions;
