import React,{memo, useEffect, useRef} from 'react';
import './css/OurMission.css';

const OurMission = memo(props => {
    const divRef = useRef(null);

    function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
     return doc.documentElement.textContent;
    }

    useEffect(()=>{
        divRef.current.innerHTML=htmlDecode(props.missionData && props.missionData.content);
    })

    return (
        <div className='our-mission'>
            <p className='our-mission-header'>{props.missionData &&props.missionData.title}</p>
            <div className="row">
                <div className="col-12">
                    <div className='mission-card'>
                         <div ref={divRef} ></div>
                    </div>
                </div>
            </div>
            
        </div>
    )
});

export default OurMission
